import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdFreeBreakfast, MdStarBorder } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				О компании и услугах | Nur-Sultan Services
			</title>
			<meta name={"description"} content={"Создание вашего идеального жилого пространства"} />
			<meta property={"og:title"} content={"О компании и услугах | Nur-Sultan Services"} />
			<meta property={"og:description"} content={"Создание вашего идеального жилого пространства"} />
			<link rel={"shortcut icon"} href={"https://trivaxon.com/img/3257685.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://trivaxon.com/img/9.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="800px" padding="50px 50px 50px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Ознакомьтесь с нашими услугами по ремонту
				</Text>
				<Text font="--base">
				В Nur-Sultan мы предоставляем широкий спектр услуг по ремонту, чтобы охватить все аспекты вашего проекта по благоустройству дома. Независимо от того, хотите ли вы обновить одну комнату или планируете комплексный ремонт всего дома, наша квалифицированная команда готова помочь вам. Мы гордимся своей способностью сочетать функциональность и эстетику, гарантируя, что каждый проект не только оправдает, но и превзойдет ваши ожидания. Наша приверженность качеству и деталям означает, что мы прекрасно оснащены для решения сложных задач и превращения вашего жилого пространства в место, которое вы с гордостью назовете своим домом.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--indigo"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Полный ремонт дома
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Полный капитальный ремонт: Преобразуйте весь дом, чтобы он соответствовал вашему стилю и потребностям. Включает в себя изменение планировки, установку новых и обновление электрических и сантехнических систем.
						<br />
						<br />
						Модернизация: Обновите свой дом с помощью новейших технологий, энергоэффективных решений и современной эстетики.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-red"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--red"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Перепланировка кухни и ванной комнаты
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Шкафы на заказ: Шкафы, изготовленные по индивидуальному заказу, максимально увеличивают пространство и повышают функциональность.
						<br/><br/>
						Светильники и фурнитура: Высококачественные раковины, душевые кабины, смесители и аксессуары, сочетающие в себе стиль и долговечность.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-green"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--green"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Гостиные и столовые
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Напольные покрытия: От твердых пород дерева до современной плитки - мы предлагаем множество вариантов напольных покрытий на любой вкус.
						<br/><br/>
						Концепции освещения: Создайте идеальную атмосферу с помощью наших индивидуальных световых решений, включая "умные" светильники, которыми вы можете легко управлять.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-orange"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--orange"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Экстерьер и ландшафтный дизайн
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Улучшение внешнего вида: Улучшите внешний вид вашего дома с помощью ландшафтного дизайна, покраски и улучшения фасада.
						<br/><br/>
						Открытые жилые пространства: Проектирование и строительство идеальных открытых площадок для отдыха и развлечений.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-purple"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--purple"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Экологически чистые обновления
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Практика устойчивого развития: Установите энергосберегающие окна, солнечные батареи и другие экологичные технологии, чтобы уменьшить углеродный след вашего дома.
						<br/><br/>
						Экологичные материалы: Использование экологически чистых, нетоксичных материалов, безопасных для вашей семьи и планеты.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-primary"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Свяжитесь с нами для получения более подробной информации
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Хотите узнать больше о том, как Nur-Sultan может преобразить ваш дом? Наша команда готова обсудить ваши конкретные потребности и помочь вам спланировать следующий проект реконструкции. Свяжитесь с нами по контактным данным, указанным на главной странице сайта, чтобы получить подробную консультацию.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://trivaxon.com/img/0.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://trivaxon.com/img/11.jpg"
					/>
				</Box>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
					margin="10px 0px 0px 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://trivaxon.com/img/12.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
					margin="10px 0px 100px 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://trivaxon.com/img/13.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fbf0fdbe26f0020fd0d1b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});